var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"offer-wrapper",attrs:{"id":"learn_more"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-8 col-md-offset-2"},[_vm._m(0),_c('p',[_vm._v(" Patty, Vicki, and Cindy preferred to add a group picture, rather than individual profile pics. As you can see, they're very photogenic. ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('br'),_c('hr'),_c('br'),_vm._m(4),_c('p',[_c('strong',[_vm._v("Jim Mowrey")]),_vm._v(" has been pushing us all up-hill for "+_vm._s(_vm.years)+" years now. His ability to build connections with clients and end users, as well as find all the weird shit they're looking for, is pretty legendary. ")]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Jimmy's Angels")]),_c('hr'),_c('br'),_c('img',{attrs:{"src":"/img/jimmys-angels.jpg","width":"100%","alt":"An image of the office staff"}}),_c('br'),_c('hr'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Vicki Williams")]),_vm._v(" has printed more invoices than anyone else we know, and that's saying something. She's most often the first person you'll hear when you call our 800 line. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Cindy Brown")]),_vm._v(" helps us keep things on the shelves, helps us find materials when no one else can, and keeps the ship moving in a generally-forward direction. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Patty Mowrey")]),_vm._v(" pays the bills, argues with vendors, and gives us all crap when we deserve it. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',[_vm._v("And the rest of the crew...")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Rusty Howell")]),_vm._v(" has been wise-cracking and come-backing throughout the Ohio Basin since dirt was a child. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ryan Kilgore")]),_vm._v(" knows a thing or two about the Southern IL / IN Oil Basin, and is learning more every day. He started working our Michigan territory when Andy Tucker retired. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Nadine Liggett")]),_vm._v(" is our Midvale girl Friday. She does a little bit of everything, to help everyone and take care of our customers. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Travis Pinkstaff")]),_vm._v(" is our Olney, Illinois warehouse specialist. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ashley Noble")]),_vm._v(" started in the business in a crib by her momma's desk. Took a break for several years, then came back a couple of years ago. She's another girl Friday. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Jesse Fleming")]),_vm._v(" provides warehouse operations support in Olney, Illinois. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Zak Schumacher")]),_vm._v(" provides warehouse operations support and inside sales in Midvale, Ohio. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Mike Murry")]),_vm._v(" handles pretty much everything in our Ohio warehouse. ")])
}]

export { render, staticRenderFns }