<template>
  <section class="offer-wrapper" id="learn_more">
    <div class="container">
      <div class="col-md-8 col-md-offset-2">
        <div class="text-center">
          <h3>Jimmy's Angels</h3>
          <hr>
          <br/>
          <img src="/img/jimmys-angels.jpg" width="100%" alt="An image of the office staff"/>
          <br/>
          <hr/>
          <br/>
        </div>
        <p>
          Patty, Vicki, and Cindy preferred to add a group picture, rather than individual profile pics. As you can see,
          they're very photogenic.
        </p>
        <p>
          <strong>Vicki Williams</strong> has printed more invoices than anyone else we know, and that's saying
          something.
          She's most often the first person you'll hear when you call our 800 line.
        </p>
        <p>
          <strong>Cindy Brown</strong> helps us keep things on the shelves, helps us find materials when no one else
          can,
          and keeps the ship moving in a generally-forward direction.
        </p>
        <p>
          <strong>Patty Mowrey</strong> pays the bills, argues with vendors, and gives us all crap when we deserve it.
        </p>
        <br>
        <hr>
        <br>
        <div class="text-center">
          <h4>And the rest of the crew...</h4>
          <br/>
        </div>
        <p>
          <strong>Jim Mowrey</strong> has been pushing us all up-hill for {{ years }} years now. His ability to build connections
          with clients and end users, as well as find all the weird shit they're looking for, is pretty legendary.
        </p>
        <p>
          <strong>Rusty Howell</strong> has been wise-cracking and come-backing throughout the Ohio Basin since dirt was
          a child.
        </p>
        <p>
          <strong>Ryan Kilgore</strong> knows a thing or two about the Southern IL / IN Oil Basin, and is learning more
          every day. He started working our Michigan territory when Andy Tucker retired.
        </p>
        <p>
          <strong>Nadine Liggett</strong> is our Midvale girl Friday. She does a little bit of everything, to help
          everyone and take care of our customers.
        </p>
        <p>
          <strong>Travis Pinkstaff</strong> is our Olney, Illinois warehouse specialist.
        </p>
        <p>
          <strong>Ashley Noble</strong> started in the business in a crib by her momma's desk. Took a break for several
          years, then came back a couple of years ago. She's another girl Friday.
        </p>
        <p>
          <strong>Jesse Fleming</strong> provides warehouse operations support in Olney, Illinois.
        </p>
        <p>
          <strong>Zak Schumacher</strong> provides warehouse operations support and inside sales in Midvale, Ohio.
        </p>
        <p>
          <strong>Mike Murry</strong> handles pretty much everything in our Ohio warehouse.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfficeTeam',
  data: function () {
    return {
      years: new Date().getFullYear() - 1983
    }
  }
}
</script>

<style lang="scss" scoped>
</style>