var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"main-header"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"general-info"},[_c('ul',[_c('li',[_c('div',{staticClass:"icon"},[_c('unicon',{attrs:{"name":"outgoing-call"}})],1),_vm._m(2)]),_c('li',[_c('div',{staticClass:"icon"},[_c('unicon',{attrs:{"name":"outgoing-call"}})],1),_vm._m(3)]),_c('li',[_c('div',{staticClass:"icon"},[_c('unicon',{attrs:{"name":"envelope-alt"}})],1),_vm._m(4)])])]),_vm._m(5)])])])]),_c('NavBar')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center hidden-md hidden-lg hidden-xl",staticStyle:{"font-weight":"bold","background-color":"#00005a","min-height":"3em","border":"2px solid silver","align-content":"center","border-radius":"9999px","margin-bottom":"1em"}},[_c('a',{staticStyle:{"color":"#FFFFFF"},attrs:{"href":"/pools/football"}},[_vm._v("2024 Football Pool - Click Here")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/img/rsi-logo-header.png","alt":"REP Sales, Inc. - Logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Olney, IL "),_c('br'),_c('span',[_c('a',{staticStyle:{"font-weight":"bold"},attrs:{"href":"tel:800-274-2003"}},[_vm._v("800-274-2003")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Midvale, OH "),_c('br'),_c('span',[_c('a',{staticStyle:{"font-weight":"bold"},attrs:{"href":"tel:800-501-7979"}},[_vm._v("800-501-7979")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Email Us "),_c('br'),_c('span',[_c('strong',[_c('a',{attrs:{"href":"mailto:info@repsales.net"}},[_vm._v("info@repsales.net")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button hidden-xs hidden-sm hidden-md"},[_c('a',{staticClass:"main-header hvr-bounce-to-right",attrs:{"href":"/#contact_us"}},[_vm._v("REQUEST A QUOTE")])])
}]

export { render, staticRenderFns }